import React from "react";
import PropTypes from 'prop-types';
import addToMailchimp from 'gatsby-plugin-mailchimp';



class MailChimpForm extends React.Component {
  constructor() {
    super();
    this.state = {email: null, msg: null, result: null, fname: null, lname: null, website: 'cbitest.com'};
  } 

  handleEmailChange = (event) => {
      this.setState({email: event.target.value});
  }

  handleFirstNameChange = (event) => {
    this.setState({fname: event.target.value});
  }

  handleLastNameChange = (event) => {
    this.setState({lname: event.target.value});
  }

  // Since `addToMailchimp` returns a promise, you
  // can handle the response in two different ways:
 
  // Note that you need to send an email & optionally, listFields
  // these values can be pulled from React state, form fields,
  // or wherever.  (Personally, I recommend storing in state).

  _handleSubmit = e => {
    e.preventDefault();
    const errorMessage = null;
    addToMailchimp(this.state.email, { 
        FNAME: this.state.fname,
        LNAME: this.state.lname,
        MMERGE9: this.state.website
    }) // listFields are optional if you are only capturing the email address.
    .then(data => {
      // I recommend setting data to React state
      // but you can do whatever you want (including ignoring this `then()` altogether)
      this.setState({msg: data.msg, result: data.result});
      //console.log(data)
    })
    .catch(() => {
      // unnecessary because Mailchimp only ever
      // returns a 200 status code
      // see below for how to handle errors
    })
  } 

  render () {

    return (
    <div id="mailchimp" className="container">
        <div className={this.state.result ? 'alert alert-info' : null} dangerouslySetInnerHTML={{__html: this.state.msg}}></div>

        <form onSubmit={this._handleSubmit}>
          <div className="container">
            <div className="row justify-content-md-center no-gutters">
              <div className="col-sm-6 form-group pr-sm-2">
                  <label htmlFor="mce-FNAME" className="sr-only">First Name </label>
                  <input 
                    type="text" 
                    name="FNAME" 
                    className="" 
                    id="mce-FNAME" 
                    placeholder="First Name"
                    onChange={this.handleFirstNameChange}
                  ></input>
              </div>
              <div className="col-sm-6 form-group">
                  <label htmlFor="mce-LNAME" className="sr-only">Last Name </label>
                  <input 
                    type="text"
                    name="LNAME"
                    className=""
                    id="mce-LNAME"
                    placeholder="Last Name"
                    onChange={this.handleLastNameChange}
                  ></input>
              </div>
            </div>
            <div className="row justify-content-md-center no-gutters">
              <div className="col-sm-9 form-group pr-sm-2">
                <label htmlFor="mce-EMAIL" className="sr-only">Email Address <span className="required">*</span></label>
                <input 
                    id="mce-EMAIL"
                    name="email"
                    type="email"
                    placeholder="Email Address (required)"
                    onChange={this.handleEmailChange}
                />
              </div>
              <div className="col-sm-3 form-group">
                <input 
                  type="hidden"
                  name="MMERGE9"
                  className=""
                  id="mce-MMERGE9"
                  value="cbitest.com"
                ></input>
                <input type="submit" value="Submit" name="subscribe" id="mc-embedded-subscribe" className="btn btn-primry"></input>
              </div>
              
          
            </div>

          </div>
            
            
            </form>
    </div>
    )
  }
}

export default (MailChimpForm);