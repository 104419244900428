import React, { useEffect } from 'react'
import emergence from 'emergence.js'

import Navibar from '../navibar/navibar'
import Footer from '../footer/footer'
import { siteMetadata } from '../../../gatsby-config'

import 'modern-normalize/modern-normalize.css'
import 'prismjs/themes/prism.css'
import 'scss/gatstrap.scss'
import 'animate.css/animate.css'
import 'font-awesome/css/font-awesome.css'
import Helmet from 'react-helmet'

import BackToTop from 'react-back-to-top'

import './style.scss'

interface Props {
  children?: React.ReactNode
  location: Location
}

const Layout: React.FC<Props> = ({ children, location }: Props) => {
  useEffect(() => {
    emergence.init()
  })

  return (
    <div className="d-flex flex-column h-100">
      <Navibar title={siteMetadata.title} location={location} />
      {children}
      <BackToTop
        mainStyle={{
            width:'100%',
            height:'100%',
            background:'url(/circle-up-solid.svg)'
        }}
        animate='rotate'
        offsetTop={0}
        step={60}
        percent={true}
        isPercent={false}
      >
        <div className="sr-only">Top of Page</div>
      </BackToTop>
      <Footer title={siteMetadata.title} author={siteMetadata.author} />
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js?render=6LfTzKwbAAAAADIlZuHGHnafR7avLdOYju45qFXU"></script>
      </Helmet>
    </div>
  )
}

export default Layout
