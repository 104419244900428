import React from 'react'
import { Helmet } from 'react-helmet'

import { SiteSiteMetadata } from '../../../types/graphql-types'

interface Props {
  site:
    | Pick<
        SiteSiteMetadata,
        'title' | 'description' | 'author' | 'twitter' | 'siteUrl'
      >
    | null
    | undefined
  title?: string
  description?: string
}

const Meta: React.FC<Props> = ({ site, title, description }: Props) => {
  const siteTitle = site?.title || 'Center for Building Innovation'
  const siteUrl = site?.siteUrl || ''
  const siteDescription = site?.description || 'CBI is  is a state-of-the-art testing and research facility, specializing in product development, forensic, and R&D testing for a wide array of steel, concrete and wood, structural framing products.'
  const pageTitle = title ? `${title} | ${siteTitle}` : siteTitle
  const pageDescription = description ? `${description}` : siteDescription
  return (
    <Helmet
      title={pageTitle}
      meta={[
        {
          name: 'twitter:site',
          content: `@${site?.twitter}`,
        },
        { property: 'og:title', content: pageTitle },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: pageDescription,
        },
        {
          property: 'og:url',
          content: `${siteUrl}/profile`,
        },
        {
          property: 'og:image',
          content: `${siteUrl}/img/full_system_testing.jpg`,
        },
        {
          property: 'description',
          content: pageDescription,
        },
      ]}
    />
  )
}
export default Meta

