import { Link } from 'gatsby'
import React from 'react'
import MailChimpForm from '../form/form' 
import './style.scss'

interface Props {
  author: string
  title: string
}

const Footer: React.FC<Props> = ({ author, title }: Props) => (
  <footer className="footer">
  <div className="brandwrapper">
    <hr />
    <div className="qualtimbrands">
        <div><a href="https://www.qualtim.com"><img className="img-responsive" src="/brands/qualtim_logo.svg" alt="Qualtim" /></a></div>
        <div><a href="https://www.drjengineering.org"><img className="img-responsive" src="/brands/drj_logo.svg" alt="DrJ Engineering" /></a></div>
        <div><a href="/"><img className="img-responsive" src="/brands/cbi_logo.svg" alt="Center for Building Innovation" /></a></div>
        <div><a href="https://www.appliedbuildingtech.com"><img className="img-responsive" src="/brands/abtg_logo.svg" alt="Applied Building Technology Group" /></a></div>
        <div><a href="https://www.pushing7.com"><img className="img-responsive" src="/brands/p7_logo.svg" alt="Pushing7" /></a></div>
        <div><a href="https://www.qualtim.com/qualtimfob"><img className="img-responsive" src="/brands/tagline.svg" alt="The Qualtim Family of Services, where building innovation thrives." /></a></div>
      </div>
    </div>
    <div id="signup">
      <p className="lead text-center">
        Sign up to stay informed or call us today at 608-310-6739.
      </p>
      <MailChimpForm/>
    </div>
    <div className="container">
      <ul>
        <li>Center for Building Innovation</li>
        <li><i className="fa fa-map-marker" aria-hidden="true"></i> 6300 Enterprise Lane, Madison, WI 53719 </li>
        <li><i className="fa fa-phone" aria-hidden="true"></i> 608-310-6739 </li>
      </ul> 
    </div>
  </footer>
)

export default Footer
